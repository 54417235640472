// Chakra imports
import {
  Modal,
  Button,
  Input,
  Box,
  TextField,
  Autocomplete
} from "@mui/material";

import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useState, useRef } from "react";
import './sentMessageDialog.css';
import MessageViewTextBar from "./messageViewTextBar";
import moment from "moment";
import { datastore } from "./../../../datastore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SENT_MESSAGE_GQL = gql`
  mutation sentInternalMessage($input: SentMessageInput!) {
    sentInternalMessage(input: $input) {
      id
      threadId
    }
  }
`;

const SEARCH_ORDERS_GQL = gql`
  query searchOrders($search: String!) {
    searchOrders(search: $search){
      id
      subcontractorOrderId
      caseNo
      estateAddress
      estateZipCode
      estateCity
      orderType
      createdAt
    }
  }
`;

export enum messageTypeEnum {
  SUBCONTRACTOR_ORDER_CORRECTION = "SUBCONTRACTOR_ORDER_CORRECTION",  // eslint-disable-line
  NORMAL_MESSAGE = "NORMAL_MESSAGE",  // eslint-disable-line
  SUBCONTRACTOR_ORDER_CANCEL = "SUBCONTRACTOR_ORDER_CANCEL",  // eslint-disable-line
}

interface IProps {
  isVisible: boolean
  onClose: any
  subcontractorOrder?: any
  order?: any
  messageType: messageTypeEnum
}

const SentMessageDialog: React.FC<IProps> = ({ onClose, isVisible, order, subcontractorOrder, messageType }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const textSubjectRef = useRef<any>(null);
  const messageTextRef = useRef<any>('');
  const client = useApolloClient();
  //const [isFilterOrdersLoading, setIsFilterOrdersLoading] = useState(false);
  const [filteredOrderOptions, setFilteredOrderOptions] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState<any>(order ? order : { id: '' });
  //const [selectedSubContractOrderId, setSelectedSubContractOrderId] = useState<any>(subcontractorOrder ? subcontractorOrder.id : null);
  const [selectedMedias, setSelectedMedias] = useState<any[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [textSubject, setTextSubject] = useState("");

  datastore.data.token = token + '';
  const [sentMessageMutation] = useMutation(SENT_MESSAGE_GQL, {
    context: {
      headers: {
        authorization: "Bearer " + datastore.data.token,
      },
    }
  });

  const CloseSentMessage = () => {
    onClose('');
  }

  const handleOrderChange = (value: any, item: any) => {
    setSelectedOrder(item);
  }

  const SentMessage = () => {
    if (messageTextRef.current === '') {
      alert(t('messages.alert.addMessage'));
      return;
    }

    if (selectedOrder.id === '') {
      alert(t('messages.alert.selectOrder'));
      return;
    }

    if (textSubject === '') {
      alert(t('messages.alert.addSubject'));
      return;
    }

    let newMessage = {} as any;
    newMessage.messageSubject = textSubject + '';
    newMessage.messageText = messageTextRef.current + '';
    newMessage.orderId = selectedOrder.orderId;
    newMessage.messageType = messageType;

    if (selectedOrder.orderType === 'SUBCONTRACTOR_ORDER') {
      newMessage.subcontractorOrderId = selectedOrder.subcontractorOrderId;
    }

    if (selectedOrder.orderType === 'ORDER') {
      newMessage.customerId = selectedOrder.customerId;
    }

    //convert selectedMedias to correct format
    let medias: any[] = [];
    if (selectedMedias) {
      selectedMedias.forEach((media) => {
        medias.push({
          mediaId: media.id,
          markerOverLay: media.markerOverLay ? media.markerOverLay : ""
        } as any);
      });
      newMessage.medias = medias;
    }

    sentMessageMutation({
      variables: {
        input: newMessage
      }
    }).then((data) => {
      //reload messages and set this in the view
      onClose(data.data.sentInternalMessage.threadId);
    });
  };

  if (!isVisible) {
    return null;
  }

  const onChangeInputHandler = (evt: any) => {
    client.query({
      query: SEARCH_ORDERS_GQL,
      variables: {
        search: evt.target.value
      },
      context: {
        headers: {
          authorization: "Bearer " + datastore.data.token,
        },
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }).then((data) => {

      const orders = data.data.searchOrders;
      if (orders.length === 0) {
        setFilteredOrderOptions([]);
        return;
      }

      const mappedOrders = orders.map((order: any) => {
        if (order.orderType === 'ORDER') {
          return {
            orderId: order.id,
            customerId: order.customerId,
            orderType: order.orderType,
            subcontractorOrderId: order.subcontractorOrderId,
            label: order.caseNo + ' - ' + order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity,
            id: order.id
          }
        }
        if (order.orderType === 'SUBCONTRACTOR_ORDER') {
          return {
            orderId: order.id,
            orderType: order.orderType,
            subcontractorOrderId: order.subcontractorOrderId,
            label: order.caseNo + ' - ' + order.estateAddress + ', ' + order.estateZipCode + ' ' + order.estateCity + ' (' + moment(order.createdAt).format('DD-MM-YYYY HH:mm') + ')',
            id: order.subcontractorOrderId
          }
        }
      });

      setFilteredOrderOptions(mappedOrders);
      setSearchTerm(evt.target.value);
    });
  };

  const handleSentMessageClicked = () => {
    //not needed button is disabled in this view
    console.log('send besked');
  }

  const handleMessageChanged = (messageText: string, selectedMedias: any[]) => {
    messageTextRef.current = messageText;
    setSelectedMedias(selectedMedias);
  }

  const handleMessageSubjectChanged = (evt: any) => {
    setTextSubject(evt.target.value);
  }

  const messageModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 670,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal onClose={CloseSentMessage} open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={messageModalStyle}>
          <div style={{ width: '600px' }} >
            <h1>{t('messages.heading.sentMessage')}</h1>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "100%" }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "100%" }}>
                  <div style={{ display: 'flex', marginBottom: '18px', alignItems: 'center' }}>
                    <div className="fieldLabel">
                      {t('messageViewSubject')}:{" "}
                    </div>
                    <Input
                      fullWidth
                      onChange={handleMessageSubjectChanged}
                      ref={textSubjectRef}
                      type="text"
                      name="mailSubject"
                      defaultValue={''}
                    />
                  </div>
                  <div style={{ display: 'flex', marginBottom: '18px', alignItems: 'center' }}>
                    <div className="fieldLabel">
                      {t('messageViewSubContractOrder')}:{" "}
                    </div>
                    {order ? (
                      <div className="fieldSelectedData">
                        {selectedOrder.caseNo + ' - ' + selectedOrder.estateAddress + ', ' + selectedOrder.estateZipCode + ' ' + selectedOrder.estateCity}
                      </div>
                    ) : (
                      <Autocomplete
                        onChange={(value, item) => { handleOrderChange(value, item) }}
                        fullWidth
                        
                        disablePortal
                        options={filteredOrderOptions}

                        renderInput={(params) => {
                          //let input = { ...params };
                          //input.InputProps.className = "MuiAutocomplete-inputRoot autocom"
                          //input.inputProps.className = "MuiAutocomplete-input autocom"
                           return <TextField defaultValue={searchTerm} variant="standard"  {...params} onChange={onChangeInputHandler} />
                      }}
                      />
                    )}
                  </div>
                  <div style={{ display: 'flex', textAlign: 'center', marginBottom: '18px' }}>
                    <div style={{ width: '100%', position: 'relative' }}>
                      <MessageViewTextBar onSentMessageClicked={handleSentMessageClicked} orderId={selectedOrder ? selectedOrder.orderId : null} showSentButton={false} onMessageChanged={handleMessageChanged} MakeInlineStyle={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <div className="BottomContainer">
              <Button onClick={CloseSentMessage} variant="outlined">{t('messages.button.cancel')}</Button>
              <Button onClick={SentMessage} variant="outlined">{t('messages.button.sentMessage')}</Button>
            </div>
          </div >
        </Box>
      </>
    </Modal >
  );
};

export default SentMessageDialog;
