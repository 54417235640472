import React, { useState, MouseEventHandler, useEffect } from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./newOrder.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import { datastore } from "../../datastore";
import { useMutation, gql } from "@apollo/client";
import Icon from "@mui/material/Icon";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import Mindworking from "../../utils/mindworking";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Menu } from "@mui/material";
import AmountSelector from "../../components/AmountSelector/AmountSelector";
import { useNavigate } from "react-router-dom";

const COMPLEATE_ORDER = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
    }
  }
`;

const NewOrder: React.FC = () => {
  const navigate = useNavigate();
  const [createOrder, { error, data }] = useMutation(COMPLEATE_ORDER);

  const productContainerRef = React.createRef<HTMLDivElement>();
  const [version, setVersion] = useState(0);
  const [mwCases, setmwCases] = useState<any>([]);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [keyCase, setKeyCase] = useState(datastore.data.orderData.keyCase);

  const [buyingTermsAccepted, setBuyingTermsAccepted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [subproducts, setSubproducts] = useState<Array<any>>([]);
  const steps = ["Sagsinformation", "Produktvalg", "Faktureringsinformation"];

  useEffect(() => {
    (async () => {
      if (!datastore.data.dataSetFromUrl) {
        if (datastore.data.providerCustomerName && datastore.data.shopNo) {
          if (datastore.data.providerCustomerName !== "" && datastore.data.shopNo !== "") {
            const MWcases = await new Mindworking().getCaseNosFromXML(datastore.data.providerCustomerName, datastore.data.shopNo);

            if (MWcases) {
              const flatCaseNos = MWcases.map((CaseNo: any) => ({ label: CaseNo }));
              const sortedCaseNos = flatCaseNos.sort((a: any, b: any) => a.label.localeCompare(b.label));
              setmwCases(sortedCaseNos);
            }
          }
        }
      }
    })();
  }, []);

  if (error) {
    return <Error errorCode={500} />;
  }

  if (data) {
    // window.location.href = data.completeAml.signUrl;
  }

  const handleNext = () => {
    let shouldValidate = false;
    const orderData = datastore.data.orderData;
    console.log("orderData", orderData);
    if (activeStep === 0) {
      if (
        orderData.caseNo === null ||
        orderData.caseNo === "" ||
        orderData.estateZipCode === null ||
        orderData.estateZipCode === "" ||
        orderData.estateAddress === null ||
        orderData.estateAddress === "" ||
        orderData.estateZipCode === null ||
        orderData.estateZipCode === "" ||
        orderData.estateCity === null ||
        orderData.estateCity === "" ||
        orderData.estateType === null ||
        orderData.estateType === "" ||
        orderData.estateLivingArea === null ||
        orderData.estateBusinessArea === null
      ) {
        shouldValidate = true;
      }

      if (['NO', 'COORDINATE_WITH_CONTACTPERSON'].includes(datastore.data.orderData.keyCase)) {

        if (
          orderData.sellerName === null ||
          orderData.sellerName === "" ||
          orderData.sellerPhone === null ||
          orderData.sellerPhone === "" ||
          orderData.sellerEmail === null ||
          orderData.sellerEmail === "" ||
          orderData.sellerEmail.indexOf("@") === -1 ||
          orderData.sellerType === null ||
          orderData.sellerType === "" ||
          orderData.estateLivingArea === null ||
          orderData.estateBusinessArea === null
        ) {
          shouldValidate = true;
        }
      }
    }

    if (activeStep === 1) {
      if (orderData.products.length === 0) {
        shouldValidate = true;
      }
    }

    if (activeStep === 2) {
      if (
        //  orderData.billingName === "" ||
        //  orderData.billingCvr === "" ||
        //  orderData.billingAddress === "" ||
        //  orderData.billingZipCode === "" ||
        //  orderData.billingCity === "" ||
        orderData.estateAgentName === "" ||
        orderData.estateAgentEmail === "" ||
        orderData.estateAgentPhone === "" ||
        (datastore.data.photographyCompany.buyingTermsUrl !== '' && buyingTermsAccepted === false)
      ) {
        shouldValidate = true;
      }
    }

    if (!shouldValidate) {
      setShouldValidate(false);
      if (productContainerRef.current) {
        productContainerRef.current.style.border = "0px";
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      if (activeStep + 1 === 3) {
        localStorage.setItem("estateAgentName", datastore.data.orderData.estateAgentName);
        localStorage.setItem("estateAgentPhone", datastore.data.orderData.estateAgentPhone);
        localStorage.setItem("estateAgentEmail", datastore.data.orderData.estateAgentEmail);

        //console.log("Getting (createOrder) id_token:", localStorage.getItem("id_token"));

        datastore.data.orderData.estateRoomCount = parseInt(datastore.data.orderData.estateRoomCount + '');
        datastore.data.orderData.estateLivingArea = parseInt(datastore.data.orderData.estateLivingArea + '');
        datastore.data.orderData.estateFloorCount = parseInt(datastore.data.orderData.estateFloorCount + '');
        datastore.data.orderData.estateBusinessArea = parseInt(datastore.data.orderData.estateBusinessArea + '');

        createOrder({
          variables: {
            input: datastore.data.orderData,
          },
          context: {
            headers: {
              authorization: "Bearer " + localStorage.getItem("id_token"),
            },
          },
        });
      }
    } else {
      setShouldValidate(true);
      if (shouldValidate) {
        setVersion(version + 1);
      }

      if (activeStep === 1) {
        if (productContainerRef.current) {
          productContainerRef.current.style.border = "1px solid red";
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productId = (event.target as HTMLInputElement).value;
    datastore.data.orderData.products = [productId];
    for (let c = 0; c < datastore.data.productCategories.length; c++) {
      for (let i = 0; i < datastore.data.productCategories[c].products.length; i++) {
        if (datastore.data.productCategories[c].products[i].id === productId) {

          datastore.data.orderData.products = [
            {
              id: productId,
              price: renderPrice(datastore.data.productCategories[c].products[i].price, 1),
              amount: 1
            }
          ];
          setSubproducts(datastore.data.productCategories[c].products[i].subProducts);
          break;
        }
      }
    }
  };

  const handleBuyingConditionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).checked) {
      setBuyingTermsAccepted(true);
    } else {
      setBuyingTermsAccepted(false);
    }
  };

  const handleSubProductAmountChange = (productId: string, amount: number) => {
    console.log("handleSubProductAmountChange", productId, amount);
    //remove sub product
    for (let i = 0; i < datastore.data.orderData.products.length; i++) {
      if (datastore.data.orderData.products[i].id === productId) {
        datastore.data.orderData.products.splice(i, 1);
      }
    }


    if (amount > 0) {
      //Add sub product
      for (let c = 0; c < datastore.data.productCategories.length; c++) {
        for (let i = 0; i < datastore.data.productCategories[c].products.length; i++) {
          for (let b = 0; b < datastore.data.productCategories[c].products[i].subProducts.length; b++) {
            if (datastore.data.productCategories[c].products[i].subProducts[b].id === productId) {

              datastore.data.orderData.products.push(
                {
                  id: productId,
                  price: renderPrice(datastore.data.productCategories[c].products[i].subProducts[b].price, amount),
                  amount: amount
                }
              );

              break;
            }
          }
        }
      }
    }
  };

  const handleSubProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productId = (event.target as HTMLInputElement).value;
    if ((event.target as HTMLInputElement).checked) {
      //Add sub product
      for (let c = 0; c < datastore.data.productCategories.length; c++) {
        for (let i = 0; i < datastore.data.productCategories[c].products.length; i++) {
          for (let b = 0; b < datastore.data.productCategories[c].products[i].subProducts.length; b++) {
            if (datastore.data.productCategories[c].products[i].subProducts[b].id === productId) {
              datastore.data.orderData.products.push(
                {
                  id: productId,
                  price: renderPrice(datastore.data.productCategories[c].products[i].subProducts[b].price, 1),
                  amount: 1,
                }
              );

              console.log("datastore.data.orderData.products", datastore.data.orderData.products);
              break;
            }
          }
        }
      }

      //datastore.data.orderData.products.push(productId);
    } else {
      //remove sub product
      for (let i = 0; i < datastore.data.orderData.products.length; i++) {
        if (datastore.data.orderData.products[i].id === productId) {
          console.log("Removing sub product");
          datastore.data.orderData.products.splice(i, 1);
          console.log("datastore.data.orderData.products", datastore.data.orderData.products);
          break;
        }
      }

      //datastore.data.orderData.products = datastore.data.orderData.products.filter((item) => item !== productId);
    }
  };

  const handleAutoCompleteCaseNoFieldChange = async (event: any, value: any) => {
    const elm = event.target as HTMLInputElement;

    let fieldName = elm.id;
    if (fieldName.indexOf('caseNo') > -1) {
      fieldName = 'caseNo'
    }

    setParam(datastore.data.orderData, fieldName, value);
    await datastore.setFromMWData(value);
    setVersion(version + 1);
  };

  const handleSelectFieldChange = async (event: SelectChangeEvent) => {
    const elm = event.target as HTMLInputElement;

    setParam(datastore.data.orderData, elm.name, elm.value);

    if (elm.name === 'keyCase') {
      setKeyCase(elm.value)
    }
  };

  const handleFieldChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const elm = event.target as HTMLInputElement;

    /*
    if (elm.id === "caseNo" && elm.value !== datastore.data.orderData.caseNo) {
      await datastore.setFromMWData(elm.value);
      setParam(datastore.data.orderData, elm.id, elm.value);
      setVersion(version + 1);
    }
    */

    if (elm.id === "estateLivingArea" || elm.id === "estateBusinessArea") {
      let area = 0;
      if (elm.value !== "") {
        area = parseInt(elm.value + '');
      }
      setParam(datastore.data.orderData, elm.id, area);

    } else {
      setParam(datastore.data.orderData, elm.id, elm.value);
    }
  };

  function setParam(obj: any, param: keyof any, value: string | number) {
    obj[param] = value;
  }

  const selectProductId: MouseEventHandler<HTMLDivElement> = (event) => {
    const productId = event.currentTarget.getAttribute("data-product_id");
    document.getElementById("radio_product_" + productId)?.click();
  };

  const selectSubProductId: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();

    const productId = event.currentTarget.getAttribute("data-product_id");
    document.getElementById("checkbox_subProduct_" + productId)?.click();
  };

  const buyingTermsWarningClass = () => {
    if (shouldValidate && !buyingTermsAccepted) {
      return "buyingTermsWarning";
    } else {
      return "";
    }
  };

  const renderCondition = (condition: string) => {
    if (condition === "") {
      return true;
    }
    const data = datastore.data; // eslint-disable-line
    console.log("condition:", condition);
    const result = eval(condition); // eslint-disable-line
    console.log("condition result:", result);
    return result
  }

  const renderPrice = (price: any, amount: number) => {
    console.log("rendering price", price);
    if (!price) {
      return 0;
    }

    let priceValue = price.price;
    if (price.supplementPrice !== 0) {
      console.log("rendering dynamic price");
      const estateTotalArea = parseInt(datastore.data.orderData.estateLivingArea + '') + parseInt(datastore.data.orderData.estateBusinessArea + '');
      console.log("estateTotalArea:", estateTotalArea);
      if (estateTotalArea > price.supplementOverSqMeters) {
        //render price + supplement
        const extraSqMeters = estateTotalArea - price.supplementOverSqMeters;
        const extraPrice = Math.ceil(extraSqMeters / price.supplementSqMeterInterval) * price.supplementPrice;
        priceValue = priceValue + extraPrice;
      }
    }

    return priceValue * amount;
  }

  const setLineBreak = (text: string) => {
    return text.replace(/\n/g, "<br/>");
  }

   //filter out productcategories that are not type of SHOP
   const productCategories = datastore.data.productCategories.filter((category: any) => 
    category.categoryType === "ESTATECASE" && renderCondition(category.condition)
  );

  if (productCategories.length === 0) {
    //alert('Ingen produkter fundet');
    //document.location.href = '/categories';
    setTimeout(() => {
    navigate('/categories');
    },200);
    return (<div></div>);
  }

  return (
    <div className="newOrder MainArea" key={version}>
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.NewOrder} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>

      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Ny bestilling</div>
            <Grid container spacing={0}>
              <Grid xs={2}>
                <div></div>
              </Grid>
              <Grid xs={8}>
                <div className="stepperContainer">
                  <Stepper activeStep={activeStep} className="stepper">
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};

                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
              </Grid>
              <Grid xs={2}>
                <div></div>
              </Grid>
            </Grid>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Grid container spacing={4}>
                  <Grid xs={12} paddingTop={"100px"} paddingBottom={"180px"}>
                    <center>
                      <h2>
                        Tak for din bestilling!
                      </h2>
                    </center>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="subheadingContainer">
                  <div className="line"></div>
                  <div className="subheading">{steps[activeStep]}</div>
                  <div className="line"></div>
                </div>
                {activeStep === 0 && (
                  <Grid container spacing={4}>
                    <Grid xs={6}>
                      {mwCases.length > 0 ? (
                        <Autocomplete
                          options={mwCases}
                          id="caseNo"
                          isOptionEqualToValue={(option, value) => {
                            return true;
                          }}
                          onChange={(event, value: any) => {
                            console.log("value:", value);
                            handleAutoCompleteCaseNoFieldChange(event, value ? value.label : '');
                          }}
                          selectOnFocus
                          defaultValue={datastore.data.orderData.caseNo}
                          onKeyUp={(event: any) => {
                            if (event.key === "Enter") {
                              event.defaultMuiPrevented = true;
                              handleAutoCompleteCaseNoFieldChange(event, event.target.value);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} label="Sags nr." variant="standard" />}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          id="caseNo"
                          label="Sags nr."
                          variant="standard"
                          defaultValue={datastore.data.orderData.caseNo}
                          onChange={handleFieldChange}
                          error={
                            shouldValidate && (Boolean(datastore.data.orderData.caseNo === "") || Boolean(datastore.data.orderData.caseNo === null))
                          }
                        />
                      )}
                    </Grid>
                    <Grid xs={6}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="sellerType-label">Nøglesag</InputLabel>
                        <Select
                          id="keyCase"
                          name={"keyCase"}
                          labelId="keyCase-label"
                          defaultValue={keyCase}
                          onChange={handleSelectFieldChange}
                          variant="standard"
                        >
                          <MenuItem value={"NO"}>Nej</MenuItem>
                          <MenuItem value={"COORDINATE_WITH_ESTATEAGENT"}>Nøglesag koordiner med ejendomsmælger</MenuItem>
                          <MenuItem value={"COORDINATE_WITH_CONTACTPERSON"}>Nøglesag koordiner med kontaktperson</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateAddress"
                        label="Adresse"
                        variant="standard"
                        onChange={handleFieldChange}
                        defaultValue={datastore.data.orderData.estateAddress}
                        error={
                          shouldValidate &&
                          (Boolean(datastore.data.orderData.estateAddress === "") || Boolean(datastore.data.orderData.estateAddress === null))
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="estateType-label">Ejendomstype</InputLabel>
                        <Select
                          id="estateType"
                          name={"estateType"}
                          labelId="estateType-label"
                          defaultValue={datastore.data.orderData.estateType ? datastore.data.orderData.estateType : ""}
                          onChange={handleSelectFieldChange}
                          variant="standard"
                        >
                          <MenuItem value={"Villa"}>Villa</MenuItem>
                          <MenuItem value={"Ejerlejlighed"}>Ejerlejlighed</MenuItem>
                          <MenuItem value={"Fritidshus"}>Fritidshus</MenuItem>
                          <MenuItem value={"Rækkehus"}>Rækkehus</MenuItem>
                          <MenuItem value={"Helårsgrund"}>Helårsgrund</MenuItem>
                          <MenuItem value={"Andelsbolig"}>Andelsbolig</MenuItem>
                          <MenuItem value={"Erhverv - Boligudlejningsejendom"}>Erhverv - Boligudlejningsejendom</MenuItem>
                          <MenuItem value={"Erhverv - Bolig og erhverv"}>Erhverv - Bolig og erhverv</MenuItem>
                          <MenuItem value={"Erhverv - Lager og produktion"}>Erhverv - Lager og produktion</MenuItem>
                          <MenuItem value={"Erhverv - Butik/Detail"}>Erhverv - Butik/Detail</MenuItem>
                          <MenuItem value={"Erhverv - Kontor/Klinik"}>Erhverv - Kontor/Klinik</MenuItem>
                          <MenuItem value={"Erhverv - Hotel/campingplads/restaurant"}>Erhverv - Hotel/campingplads/restaurant</MenuItem>
                          <MenuItem value={"Erhverv - Erhvervsgrund"}>Erhverv - Erhvervsgrund</MenuItem>

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateZipCode"
                        label="Post nr."
                        variant="standard"
                        onChange={handleFieldChange}
                        defaultValue={datastore.data.orderData.estateZipCode}
                        error={
                          shouldValidate &&
                          (Boolean(datastore.data.orderData.estateZipCode === "") || Boolean(datastore.data.orderData.estateZipCode === null))
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateCity"
                        label="By"
                        variant="standard"
                        defaultValue={datastore.data.orderData.estateCity}
                        onChange={handleFieldChange}
                        error={
                          shouldValidate &&
                          (Boolean(datastore.data.orderData.estateCity === "") || Boolean(datastore.data.orderData.estateCity === null))
                        }
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateFloorCount"
                        label="Antal etager"
                        variant="standard"
                        defaultValue={datastore.data.orderData.estateFloorCount}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateRoomCount"
                        label="Antal værelser"
                        variant="standard"
                        defaultValue={datastore.data.orderData.estateRoomCount}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateLivingArea"
                        label="Boligareal"
                        variant="standard"
                        type="number"
                        defaultValue={datastore.data.orderData.estateLivingArea}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="estateBusinessArea"
                        label="Erhvervsareal"
                        variant="standard"
                        type="number"
                        defaultValue={datastore.data.orderData.estateBusinessArea}
                        onChange={handleFieldChange}
                      />
                    </Grid>


                    {['NO', 'COORDINATE_WITH_CONTACTPERSON'].includes(datastore.data.orderData.keyCase) && (
                      <>
                        <Grid xs={12}>&nbsp;</Grid>
                        <Grid xs={6}>
                          <TextField
                            fullWidth
                            id="sellerName"
                            label="Sælger navn"
                            variant="standard"
                            defaultValue={datastore.data.orderData.sellerName}
                            onChange={handleFieldChange}
                            error={
                              shouldValidate &&
                              (Boolean(datastore.data.orderData.sellerName === "") || Boolean(datastore.data.orderData.sellerName === null))
                            }
                          />
                        </Grid>
                        <Grid xs={6}>
                          <TextField
                            fullWidth
                            id="sellerPhone"
                            label="Sælger telefon"
                            variant="standard"
                            defaultValue={datastore.data.orderData.sellerPhone}
                            onChange={handleFieldChange}
                            error={
                              shouldValidate &&
                              (Boolean(datastore.data.orderData.sellerPhone === "") || Boolean(datastore.data.orderData.sellerPhone === null))
                            }
                          />
                        </Grid>
                        <Grid xs={6}>
                          <TextField
                            fullWidth
                            id="sellerEmail"
                            label="Sælger email"
                            variant="standard"
                            defaultValue={datastore.data.orderData.sellerEmail}
                            onChange={handleFieldChange}
                            error={
                              shouldValidate &&
                              (Boolean(datastore.data.orderData.sellerEmail === "") ||
                                datastore.data.orderData.sellerEmail.indexOf("@") === -1 ||
                                Boolean(datastore.data.orderData.sellerEmail === null))
                            }
                          />
                        </Grid>
                        <Grid xs={6}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel id="sellerType-label">Sælger type</InputLabel>
                            <Select
                              id="sellerType"
                              name={"sellerType"}
                              labelId="sellerType-label"
                              defaultValue={datastore.data.orderData.sellerType ? datastore.data.orderData.sellerType : "Privat"}
                              onChange={handleSelectFieldChange}
                              variant="standard"
                            >
                              <MenuItem value={"Privat"}>Privat</MenuItem>
                              <MenuItem value={"Erhverv"}>Erhverv</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}

                {activeStep === 1 && (
                  <>
                    <div ref={productContainerRef}>
                      <RadioGroup defaultValue="" name="radio-product-group" onChange={handleProductChange}>
                        {datastore.data.productCategories.map((productCategory) => {
                          return (
                            <>
                              {renderCondition(productCategory.condition) && (
                                <>
                                  {productCategory.showOnOrderForm && <div className="productCategoryTitle">{productCategory.name}</div>}
                                  <Grid container spacing={4} key={productCategory.id}>
                                    {productCategory.products.map((product: any) => {
                                      return (
                                        <>
                                          {renderCondition(product.condition) && (

                                            <Grid md={6} xs={12} xl={6} key={product.id}>
                                              <div className="productItem" data-product_id={product.id} onClick={selectProductId}>
                                                <div className="productItemRadio radio-item">
                                                  <Radio style={{ background: 'red !important' }} value={product.id} name="radio-buttons" id={"radio_product_" + product.id} />
                                                </div>
                                                <div className="productItemImage">
                                                  {product.imageUrl ? (
                                                    <img src={product.imageUrl} alt="produkt billede" />
                                                  ) : (
                                                    <div className="defaultProductImage">
                                                      <Icon>add_a_photo</Icon>
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="productItemInfo">
                                                  <div className="productItemInfoName">{product.name}</div>
                                                  <div
                                                    className="productItemInfoDescrition tooltip"
                                                    dangerouslySetInnerHTML={{ __html: product.description.replace(/\n/g, "<br/>") }}
                                                  ></div>
                                                </div>
                                                <div className="productItemPrice">{renderPrice(product.price, 1)} kr. <div className="productItemPriceExTax">ex. moms</div></div>
                                              </div>
                                            </Grid>
                                          )}
                                        </>

                                      );
                                    })}
                                  </Grid>
                                </>
                              )}
                            </>
                          );
                        })}
                      </RadioGroup>
                    </div>
                    <div className="addonContainer">
                      {subproducts.length > 0 && <div className="addonHeading">TILVALG</div>}

                      <Grid container spacing={4}>
                        {subproducts.length > 0 && (
                          <>
                            {subproducts.map((product) => {
                              return (
                                <>
                                  {renderCondition(product.condition) && (
                                    <Grid md={6} xs={12} xl={6} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                      <>
                                        <div className="subProductItem" key={product.id}>
                                          {product.allowMultiple ? (
                                            <AmountSelector initialAmount={0} productId={product.id} onAmountChange={handleSubProductAmountChange} />
                                          ) : (
                                            <div className="subProductItemCheckbox">
                                              <Checkbox value={product.id} id={"checkbox_subProduct_" + product.id} onChange={handleSubProductChange} />
                                            </div>
                                          )}
                                          <div className="subproductItemInfo" data-product_id={product.id} onClick={selectSubProductId}>
                                            <div className="subproductItemInfoName">
                                              {product.name} - {renderPrice(product.price, 1)} kr. <span className="subproductItemPriceExTax">ex. moms</span>
                                            </div>
                                            <div className="subproductItemInfoDescrition" dangerouslySetInnerHTML={{ __html: setLineBreak(product.description) }}></div>
                                          </div>
                                        </div>
                                      </>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                          </>
                        )}
                      </Grid>

                      <Grid container spacing={4}>
                        <Grid md={6} xs={12} xl={6} style={{ paddingTop: "30px" }}>
                          <div className="addonHeading ">Kommentar</div>
                          <div>
                            <TextField
                              placeholder="Tilføj et ønske eller anden kommentar til ordren"
                              multiline
                              fullWidth
                              rows={4}
                              id="note"
                              onChange={handleFieldChange}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}

                {activeStep === 2 && (
                  <Grid container spacing={4}>
                    <Grid xs={12} md={6} xl={6}>
                      <TextField
                        fullWidth
                        id="estateAgentName"
                        label="Ejendomsmægler kontakt person"
                        variant="standard"
                        onChange={handleFieldChange}
                        defaultValue={datastore.data.orderData.estateAgentName}
                        error={shouldValidate && Boolean(datastore.data.orderData.estateAgentName === "")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} xl={6}>
                      <TextField
                        fullWidth
                        id="estateAgentPhone"
                        label="Ejendomsmægler telefon"
                        variant="standard"
                        onChange={handleFieldChange}
                        defaultValue={datastore.data.orderData.estateAgentPhone}
                        error={shouldValidate && Boolean(datastore.data.orderData.estateAgentPhone === "")}
                      />
                    </Grid>
                    <Grid xs={12} md={12} xl={12}>
                      <TextField
                        fullWidth
                        id="estateAgentEmail"
                        label="Ejendomsmægler e-mail"
                        variant="standard"
                        onChange={handleFieldChange}
                        defaultValue={datastore.data.orderData.estateAgentEmail}
                        error={shouldValidate && Boolean(datastore.data.orderData.estateAgentEmail === "")}
                      />
                    </Grid>

                    {datastore.data.photographyCompany.buyingTermsUrl !== "" && (
                      <Grid xs={12} md={12} xl={12}>
                        <br />
                        <div className={buyingTermsWarningClass()}>
                          <FormGroup className="buyingTermsContainer">
                            <Checkbox defaultChecked={false} onChange={handleBuyingConditionsChange} style={{ paddingLeft: "0px" }} />

                            <span>
                              Accepter{" "}
                              <a href={datastore.data.photographyCompany.buyingTermsUrl} className="buyingTermsLink" target="_blank" rel="noreferrer">
                                generelle vilkår og betingelser.
                              </a>
                            </span>
                          </FormGroup>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 6, justifyContent: "flex-end" }} paddingTop={"40px"}>
                  {activeStep > 0 && (
                    <Button className="button" variant="contained" color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Tilbage
                    </Button>
                  )}

                  <Button className="button" variant="contained" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Bestil" : "Næste"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </div>

          <div className="photographyCompanyContactInfo">
            {datastore.data.photographyCompany.phoneNo} - {datastore.data.photographyCompany.email} - {datastore.data.photographyCompany.zipCode}{" "}
            {datastore.data.photographyCompany.city}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewOrder;

/*

                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="billingName"
                        label="Kontor / Navn"
                        variant="standard"
                        defaultValue={datastore.data.orderData.billingName}
                        onChange={handleFieldChange}
                        error={shouldValidate && Boolean(datastore.data.orderData.billingName === "")}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="billingCvr"
                        label="CVR"
                        variant="standard"
                        defaultValue={datastore.data.orderData.billingCvr}
                        onChange={handleFieldChange}
                        error={shouldValidate && Boolean(datastore.data.orderData.billingCvr === "")}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextField
                        fullWidth
                        id="billingAddress"
                        label="Adresse"
                        variant="standard"
                        defaultValue={datastore.data.orderData.billingAddress}
                        onChange={handleFieldChange}
                        error={shouldValidate && Boolean(datastore.data.orderData.billingAddress === "")}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="billingZipCode"
                        label="Post nr."
                        variant="standard"
                        defaultValue={datastore.data.orderData.billingZipCode}
                        onChange={handleFieldChange}
                        error={shouldValidate && Boolean(datastore.data.orderData.billingZipCode === "")}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <TextField
                        fullWidth
                        id="billingCity"
                        label="By"
                        variant="standard"
                        defaultValue={datastore.data.orderData.billingCity}
                        onChange={handleFieldChange}
                        error={shouldValidate && Boolean(datastore.data.orderData.billingCity === "")}
                      />
                    </Grid>
                    */
