import React, { useState } from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./categorySelector.css";
import { datastore } from "../../datastore";
import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Card, CardContent, CardHeader, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from 'react-datepicker';
import { da as daLocale, id } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";


//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";

const GET_CATEGORIES = gql`
  query getProductCategories($customerId: ID!) {
    getProductCategories(customerId: $customerId) {
      id
      name
      categoryType
    }
  }
`;

registerLocale('da', daLocale);

const CategorySelector: React.FC = () => {
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    variables: {
      customerId: datastore.data.orderData.customerId ? datastore.data.orderData.customerId : localStorage.getItem("customerId")
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <Error errorCode={500} />;
  }

  if (!data) {
    return (<Error errorCode={500} />);
  }

  const handleEstateClicked = (row: any) => {
    navigate('/neworder');
  }

  const handleShopClicked = (row: any) => {
    navigate('/newshoporder');
  }

  const handleEmployeeClicked = (row: any) => {
    navigate('/newemployeeorder');
  }

  const haveMultipleCategoryTypes = () => {
    let categoryTypes = data.getProductCategories.map((category: any) => category.categoryType);
    let uniqueCategoryTypes = categoryTypes.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    return uniqueCategoryTypes.length > 1;
  }

  const haveEstateCategory = () => {
    return data.getProductCategories.some((category: any) => category.categoryType === 'ESTATECASE');
  }

  const haveShopCategory = () => {
    return data.getProductCategories.some((category: any) => category.categoryType === 'SHOP');
  }

  const haveEmployeeCategory = () => {
    return data.getProductCategories.some((category: any) => category.categoryType === 'EMPLOYEE');
  }

  const haveOtherCategory = () => {
    return data.getProductCategories.some((category: any) => category.categoryType === 'OTHER');
  }

  if (!haveMultipleCategoryTypes()) {

    //find the first category and navigate to the correct page
    let category = data.getProductCategories[0].categoryType;

    switch (category) {
      case 'ESTATECASE':
        navigate('/neworder');
        break;
      case 'SHOP':
        navigate('/newshoporder');
        break;
      case 'EMPLOYEE':
        navigate('/newemployeeorder');
        break;
      case 'OTHER':
        navigate('/newotherorder');
        break;
      default:
        navigate('/neworder');
        break;
    }

  }

  return (
    <div className="categorySelector MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.NewOrder} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>

          <div className="contentContainer">
            <div className="Heading">Ny bestilling</div>

            <div className="categoriesContainer">
              {haveEstateCategory() && (
                <div className="categoryCard" onClick={handleEstateClicked}>
                  <Paper elevation={3} style={{ height: '300px', width: '300px', cursor: 'pointer' }} >
                    <Card style={{ height: '300px' }}>
                      <div className="categoryImageContainer">
                        <img className="categoryImage" src="/icons/estate.png" alt="Ejendom" />
                      </div>
                      <CardHeader title={'Ejendom'} subheader={'Klik her, hvis du ønsker at bestille et sags relatereret produkt'} />
                      <CardContent style={{ height: '100%' }}>
                        <div style={{ height: '100%' }}>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </div>
              )}

              {haveShopCategory() && (
                <div className="categoryCard" onClick={handleShopClicked}>
                  <Paper elevation={3} style={{ height: '300px', width: '300px', cursor: 'pointer' }} >
                    <Card style={{ height: '300px' }}>
                      <div className="categoryImageContainer">
                        <img className="categoryImage" src="/icons/shop.png" alt="Butik" />
                      </div>
                      <CardHeader title={'Butik'} subheader={'Klik her, hvis du ønsker at bestille et butiks relatereret produkt'} />
                      <CardContent style={{ height: '100%' }}>
                        <div style={{ height: '100%' }}>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </div>
              )}

              {haveEmployeeCategory() && (
                <div className="categoryCard" onClick={handleEmployeeClicked}>
                  <Paper elevation={3} style={{ height: '300px', width: '300px', cursor: 'pointer' }} >
                    <Card style={{ height: '300px' }}>
                      <div className="categoryImageContainer">
                        <img className="categoryImage" src="/icons/portrait.png" alt="Medarbejder" />
                      </div>
                      <CardHeader title={'Medarbejder'} subheader={'Klik her, hvis du ønsker at bestille et medarbejder relatereret produkt'} />
                      <CardContent style={{ height: '100%' }}>
                        <div style={{ height: '100%' }}>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </div>
              )}

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategorySelector;
