import React from "react";
import Error from "../error/error";
import { FaRegPlayCircle } from "react-icons/fa";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderInfo.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import Paper from '@mui/material/Paper';
import { useParams } from "react-router-dom";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { datastore } from "../../datastore";
import { Card, CardContent, CardHeader, Snackbar, TextField, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css"
import CustomerMessages from "../../components/Messages/CustomerMessages";

const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

const GET_ORDER = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      caseNo
      textHeading
      textSmall
      textMedium
      textLarge
      textSoMe
      textWebsite
      orderType
      downloadAllMediaUrl
      texts {
        id
        name
        text
        sorting
        section {
              name
              textType
              textLength
            }
      }

      estateAddress
      estateZipCode
      estateCity
      estateType
      estateFloorCount
      estateRoomCount
      estateLivingArea
      estateBusinessArea
      sellerName
      sellerPhone
      sellerEmail
      sellerType
      estateAgentName
      estateAgentEmail
      estateAgentPhone
      note
      photeshotEvent {
        id
        start
        end
      }
      products {
        id
        name
        description
      }
      media {
        id
        thumpnailUrl,
        orgUrl,
        heightInPx,
        widthInPx,
        mimeType,
        description,
        filename
      }
      status
      createdAt
      updatedAt
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const OrderInfo: React.FC = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [tabSelected, setTabSelected] = React.useState(0);
  const [openCopyToClipboardInfo, setOpenCopyToClipboardInfo] = React.useState(false);
  const [openCopyTextToClipboardInfo, setOpenCopyTextToClipboardInfo] = React.useState(false);
  const [openSavedInfo, setOpenSavedInfo] = React.useState(false);
  const [openNoteSavedInfo, setOpenNoteSavedInfo] = React.useState(false);
  const [openErrorInfo, setOpenErrorInfo] = React.useState(false);
  const [currentNoteText, setCurrentNoteText] = React.useState("");
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState(0);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const thumbnailsRef = React.useRef(null);
  const captionsRef = React.useRef(null);

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      id: id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <div></div>;
  }

  if (error) {
    //return <Error errorCode={500} />;
  }

  if (!data) {
    //return (<Error errorCode={500} />);
  }


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const translateStatus = (status: string) => {
    switch (status) {
      case 'RECEIVED':
        return 'Modtaget'
      case 'SELLER_NOT_RESPONDING':
        return 'Kontaktperson træffes ikke'
      case 'AWAITING_RESPONSE_FROM_CONTACT_PERSON':
        return 'Afventer svar fra kontaktperson'
      case 'PUT_ON_HOLD':
        return 'Ordren er sat i bero'
      case 'FOTOSHOT_PLANED':
        return 'Fotografering planlagt'
      case 'BEING_PROCESSED':
        return 'Ordren efterbehandles'
      case 'PROCESSING_COMPLETED':
        return 'Efterbehandling er afsluttet'
      case 'DELIVERED':
        return 'Ordren er leveret'
      case 'INVOICED':
        return 'Ordren er faktureret'
      case 'CANCELED':
        return 'Ordren er annulleret'
      default:
        break;
    }
  }

  const handleDataChange = (event: any) => {
    let inputData = JSON.parse('{"' + event.target.name + '":"' + event.target.value + '"}');
    inputData.id = data.getOrder.id;

    updateOrder({ variables: { input: inputData } }).then(() => {
      setOpenSavedInfo(true)
    }).catch((error) => {
      console.log('Error updating order:', error);
      setOpenErrorInfo(true);
    });

  }

  const handleNoteFocus = (event: any) => {
    const currentText = event.target.value;
    setCurrentNoteText(currentText);
  };

  const handleNoteChange = (event: any) => {
    let noteText = event.target.value;

    if (noteText === currentNoteText) {
      console.log('Note text has not changed');
      return;
    }

    noteText = noteText.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    let inputData = JSON.parse('{"note":"' + noteText + '"}');
    inputData.id = data.getOrder.id;

    updateOrder({ variables: { input: inputData } }).then(() => {
      setOpenNoteSavedInfo(true)
    }).catch((error) => {
      console.log('Error updating order:', error);
      setOpenErrorInfo(true);
    });
  };

  const handleNoteInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNoteSavedInfo(false);
  };

  const handleErrorClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorInfo(false);
  };

  const handleInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSavedInfo(false);
  };

  const handleCopyToTextClipboardInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCopyTextToClipboardInfo(false);
  };

  const handleCopyToClipboardInfoClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCopyToClipboardInfo(false);
  };



  const handleCopyToClipBoard = (textId: string) => {
    // find the text to copy
    let textToCopy = "";
    if (textId !== "") {
      let text = data.getOrder.texts.find((text: any) => text.id === textId);
      if (text) {
        textToCopy = text.text;
        // Copy the text inside the text field
        navigator.clipboard.writeText(textToCopy);
        setOpenCopyTextToClipboardInfo(true);
      }
    } else {
      textToCopy = window.location.hostname + "/media/" + data.getOrder.id;
      // Copy the text inside the text field
      navigator.clipboard.writeText(textToCopy);
      setOpenCopyToClipboardInfo(true);
    }
  }

  const handleCopyDownloadLinkToClipBoard = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(data.getOrder.downloadAllMediaUrl);
    setOpenCopyToClipboardInfo(true);
  }

  const addLinebreaks = (text: String) => {
    return text.replaceAll("\\n", "\n");
  };

  //map media to slides
  const slides = data.getOrder.media.map((media: any) => {
    if (media.mimeType === "video/mp4") {
      return { description: media.filename + "\n(" + media.heightInPx + " x " + media.widthInPx + ")", poster: media.thumpnailUrl, width: media.widthInPx, height: media.heightInPx, type: "video", sources: [{ src: media.orgUrl, type: "video/mp4" }] };
    } else {
      return { description: media.filename + "\n(" + media.heightInPx + " x " + media.widthInPx + ")", src: media.orgUrl, width: media.widthInPx, height: media.heightInPx, SrcSet: [{ src: media.thumpnailUrl }], type: "image" };
    }
  });

  const getTextView = () => {

    if (data.getOrder.orderType !== 'ESTATECASE') {
      return "NONE";
    }

    if (data.getOrder.texts.length > 0) {
      if (data.getOrder.texts[0].section) {
        return "SectionTexts";
      } else {
        return "AITexts";
      }
    }

    return "NormalTexts";
  }

  const handleDownloadAllClicked = () => {
    let downloadLink = document.createElement('a');
    downloadLink.href = data.getOrder.downloadAllMediaUrl;
    downloadLink.click();
  }

  const nodeGridSize = (data.getOrder.orderType === 'ESTATECASE' || data.getOrder.orderType === 'SHOP') ? 6 : 12;

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.OrderList} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Ordre information</div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Ordre information'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Status:</div>
                          <div className="itemData">{translateStatus(data.getOrder.status)}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Sags nr.:</div>
                          <div className="itemData">{data.getOrder.caseNo}</div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">Adresse:</div>
                          <div className="itemData">{data.getOrder.estateAddress}<br />
                            {data.getOrder.estateZipCode} {data.getOrder.estateCity} </div>
                        </div>


                        <div className="item">
                          <div className="itemLabel">Produkt(er):</div>

                          <div className="itemDataProductContainer">
                            {data.getOrder.products.map((product: any) => {

                              return (
                                <div className="itemDataProduct" key={product.id}>
                                  {product.name}
                                </div>
                              )
                            })}
                          </div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Fotografering d.:</div>
                          {data.getOrder.photeshotEvent ? (
                            <div className="itemData">
                              {data.getOrder.photeshotEvent.start.substr(0, 10)}  Kl.:{String(new Date(data.getOrder.photeshotEvent.start).getHours()).padStart(2, '0') + ":" + String(new Date(data.getOrder.photeshotEvent.start).getMinutes()).padStart(2, '0')} - {String(new Date(data.getOrder.photeshotEvent.end).getHours()).padStart(2, '0') + ":" + String(new Date(data.getOrder.photeshotEvent.end).getMinutes()).padStart(2, '0')}
                            </div>
                          ) : (
                            <div className="itemData">Ikke planlagt</div>
                          )}
                        </div>

                        <div className="item">
                          <div className="itemLabel">Oprettet:</div>
                          <div className="itemData"> {data.getOrder.createdAt.split('T')[0]}</div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                </Paper>
              </Grid>

              <Grid xs={6} spacing={2}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <CustomerMessages orderId={data.getOrder.id} />
                </Paper>
              </Grid>

              {data.getOrder.orderType === 'ESTATECASE' && (
                <Grid xs={6}>
                  <Paper elevation={3} style={{ height: '100%' }}>
                    <Card style={{ height: '100%' }}>
                      <CardHeader title={'Adresse information'} />
                      <CardContent>
                        <div>


                          <div className="item">
                            <div className="itemLabel">Ejendomstype:</div>
                            <div className="itemData">{data.getOrder.estateType}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">Antal etager:</div>
                            <div className="itemData">{data.getOrder.estateFloorCount}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">Antal rum:</div>
                            <div className="itemData">{data.getOrder.estateRoomCount}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">Bolig areal:</div>
                            <div className="itemData">{data.getOrder.estateLivingArea}</div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">Erhvervs areal:</div>
                            <div className="itemData">{data.getOrder.estateBusinessArea}</div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}

              {data.getOrder.orderType !== 'SHOP' && (
                <Grid xs={6}>
                  <Paper elevation={3} style={{ height: '100%' }}>
                    <Card style={{ height: '100%' }}>
                      {data.getOrder.orderType === 'ESTATECASE' ? (
                        <CardHeader title={'kontaktperson'} />
                      ) : (
                        <CardHeader title={'Medarbejder'} />
                      )}
                      <CardContent>
                        <div>
                          <div className="item">
                            <div className="itemLabel">Navn:</div>
                            <div className="itemData"><TextField
                              fullWidth
                              size="small"
                              name="sellerName"
                              variant="standard"
                              defaultValue={data.getOrder.sellerName} onBlur={handleDataChange} />
                            </div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">Telefon nr.:</div>
                            <div className="itemData">

                              <TextField
                                fullWidth
                                size="small"
                                name="sellerPhone"
                                variant="standard"
                                defaultValue={data.getOrder.sellerPhone} onBlur={handleDataChange} />
                            </div>
                          </div>

                          <div className="item">
                            <div className="itemLabel">E-mail:</div>
                            <div className="itemData">
                              <TextField
                                fullWidth
                                size="small"
                                name="sellerEmail"
                                variant="standard"
                                defaultValue={data.getOrder.sellerEmail} onBlur={handleDataChange} />

                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}
              <Grid xs={6}>
                <Paper elevation={3} style={{ height: '100%' }}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Kontakt information'} />
                    <CardContent>
                      <div>
                        <div className="item">
                          <div className="itemLabel">Kontakt person:</div>
                          <div className="itemData">{data.getOrder.estateAgentName}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Kontakt telefon nr.:</div>
                          <div className="itemData">{data.getOrder.estateAgentPhone}</div>
                        </div>

                        <div className="item">
                          <div className="itemLabel">Kontakt e-mail:</div>
                          <div className="itemData">{data.getOrder.estateAgentEmail}</div>
                        </div>

                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>



              <Grid xs={nodeGridSize}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardHeader title={'Noter'} subheader={'Noter fra bestilling. '} />
                    <CardContent style={{ height: '100%' }}>
                      <div style={{ height: '100%' }}>
                        <TextField
                          disabled
                          fullWidth
                          maxRows={6}
                          rows={6}
                          style={{ height: '100%' }}
                          multiline defaultValue={addLinebreaks(data.getOrder.note)} onFocus={handleNoteFocus} onBlur={handleNoteChange} />
                      </div>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              {/* Images and videos */}
              {data.getOrder.media.length > 0 && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={'Media'}
                        action={
                          <>
                            <Button variant="outlined" onClick={() => { handleCopyDownloadLinkToClipBoard() }}>Kopier download link</Button>
                            <span style={{ width: '10px' }}>&nbsp;</span>
                            <Button variant="outlined" onClick={() => { handleCopyToClipBoard("") }}>Kopier link til billedfremviser</Button>
                            <span style={{ width: '10px' }}>&nbsp;</span>
                            <Button variant="outlined" onClick={() => { handleDownloadAllClicked() }}>Hent billeder</Button>
                          </>
                        }
                      />
                      <CardContent>
                        <div>
                          <div className="mediaContainer">
                            {data.getOrder.media.map((media: any, index: number) => {
                              return (
                                <div key={media.id} className="mediaItem" onClick={() => { setSelectedImage(index); setOpenImageViewer(true) }}>
                                  <img src={media.thumpnailUrl} alt="" />
                                  {media.mimeType === "video/mp4" && <div className="playIcon">
                                    <div className="orderMediaListVideoPlayIconContainer">
                                      <FaRegPlayCircle className="orderMediaListVideoPlayIcon" />
                                    </div>
                                  </div>}

                                  <div className="mediaDescription">{media.description}</div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}

              {getTextView() === 'SectionTexts' && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={'Tekster'} />
                      <CardContent>
                        <div>
                          {data.getOrder.texts.map((text: any, index: number) => {
                            return (
                              <div key={index} className="item">
                                <div className="itemLabelText">{text.section.name} ({text.section.textLength} tegn):</div>
                                <div className="itemDataText" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n\r', '<br/>').replaceAll('\n', '<br/>').replaceAll('\r', '<br/>') }}></div>
                              </div>
                            )
                          })
                          }
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>

              )}

              {getTextView() === 'AITexts' && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={'Tekster'} />
                      <CardContent>
                        <>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabSelected} onChange={handleTabChange} aria-label="basic tabs example">
                              <Tab label="Tilpassede tekster" {...a11yProps(0)} />
                              <Tab label="Rå tekster" {...a11yProps(1)} />
                            </Tabs>
                          </Box>

                          <CustomTabPanel value={tabSelected} index={0}>
                            <div>
                              {data.getOrder.texts.map((text: any, index: number) => {
                                return (

                                  <div key={index} className="item">

                                    <div className="itemLabelText">{text.name}:</div>
                                    <div className="itemDataText" dangerouslySetInnerHTML={{ __html: text.text.replaceAll('\n\r', '<br/>').replaceAll('\n', '<br/>').replaceAll('\r', '<br/>') }}></div>
                                    <div className="itemCopyText" onClick={() => { handleCopyToClipBoard(text.id) }}>
                                      <svg fill="#000000" width="20px" height="20px" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z" ></path><path d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"></path><path d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"></path><path d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"></path>
                                        <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
                                      </svg>
                                    </div>
                                  </div>
                                )

                              })
                              }
                            </div>
                          </CustomTabPanel>
                          <CustomTabPanel value={tabSelected} index={1}>
                            <div>
                              <div className="item">
                                <div className="itemLabelText">Overskrift:</div>
                                <div className="itemDataText" dangerouslySetInnerHTML={{ __html: data.getOrder.textHeading.replaceAll('\n', '<br/>') }}></div>
                              </div>
                              <div className="item">
                                <div className="itemLabelText">Normal tekst:</div>
                                <div className="itemDataText" dangerouslySetInnerHTML={{ __html: data.getOrder.textLarge.replaceAll('\n', '<br/>') }}></div>
                              </div>
                              <div className="item">
                                <div className="itemLabelText">SoMe tekst:</div>
                                <div className="itemDataText" dangerouslySetInnerHTML={{ __html: data.getOrder.textSoMe.replaceAll('\n', '<br/>') }}></div>

                              </div>
                            </div>
                          </CustomTabPanel>
                        </>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>

              )}


              {getTextView() === 'NormalTexts' && (
                <Grid xs={12}>
                  <Paper elevation={3} >
                    <Card>
                      <CardHeader title={t('OrderMediaTextHeading')} />
                      <CardContent>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputHeading')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textHeading.replace(/\n/g, '<br />') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputTextSmall')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textSmall.replace(/\n/g, '<br />') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputTextMedium')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textMedium.replace(/\n/g, '<br />') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputTextLarge')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textLarge.replace(/\n/g, '<br />') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputTextSoMe')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textSoMe.replace(/\n/g, '<br />') }}></div>
                        </div>
                        <div className="item">
                          <div className="itemLabel">{t('OrderMediaInputTextWebsite')}:</div>
                          <div className="itemData" style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.getOrder.textWebsite.replace(/\n/g, '<br />') }}></div>
                        </div>
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={openNoteSavedInfo}
        autoHideDuration={4000}
        message="Ændringen er blevet gemt og fotografen er blevet informeret."
        onClose={handleNoteInfoClose}
      />

      <Snackbar
        open={openSavedInfo}
        autoHideDuration={2000}
        message="Ændringen er blevet gemt."
        onClose={handleInfoClose}
      />

      <Snackbar
        open={openCopyToClipboardInfo}
        autoHideDuration={4000}
        message="Linket er kopieret til udklipsholderen."
        onClose={handleCopyToClipboardInfoClose}
      />

      <Snackbar
        open={openCopyTextToClipboardInfo}
        autoHideDuration={4000}
        message="Teksten er kopieret til udklipsholderen."
        onClose={handleCopyToTextClipboardInfoClose}
      />

      <Snackbar
        open={openErrorInfo}
        autoHideDuration={2000}
        ContentProps={{
          sx: {
            background: "red"
          }
        }}
        message="Opdatering af ordren fejlede."
        onClose={handleErrorClose}
      />

      <Lightbox
        styles={{ thumbnailsContainer: { backgroundColor: "#353535" } }}
        plugins={[Thumbnails, Counter, Video, Captions]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        thumbnails={{ ref: thumbnailsRef }}
        open={openImageViewer}
        close={() => setOpenImageViewer(false)}
        slides={slides}
        index={SelectedImage}
        captions={{ ref: captionsRef, descriptionTextAlign: "center" }}
      />
    </div>
  );
};

export default OrderInfo;
