import React from "react";

//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./orderBookingCalender.css";
import { datastore } from "../../datastore";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Paper} from "@mui/material";
import PlanningCalendar from "../../components/PlanningCalendar/PlanningCalendar";


const OrderBookingCalender: React.FC = () => {
  
 
  return (
    <div className="settings MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Settings} view={viewType.EstateAgent} customerName={datastore.data.customer.name} />
      </header>

      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Indstillinger</div>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  
                  <PlanningCalendar fullAddress={'Hjorts alle 44, 8660 skanderborg'} customerId="ff2edf2c-e08c-44b0-a46d-b84925a86f3a" duration={60} date={null} />



                </Paper>
              </Grid>
              
            </Grid>
          </div>
        </Grid>
      </Grid>
     
    </div>
  );
};

export default OrderBookingCalender;
