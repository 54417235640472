// Chakra imports
import {
  Modal,
  Button,
  Box
} from "@mui/material";
import React from "react";
import './messageViewImageMarker.css';
import * as markerjs2 from 'markerjs2';
import { useTranslation } from "react-i18next";

interface IProps {
  isVisible: boolean;
  media: any;
  onClose: any;
}

const MessageViewImageMarker: React.FC<IProps> = ({ media, onClose, isVisible }) => {
  const { t } = useTranslation();
  const imgRef = React.createRef<HTMLImageElement>();
  let markerArea: any | null = null;
  if (!isVisible) {
    return null;
  }

  const CloseMessageViewImageMarker = () => {
    if (markerArea) {
      markerArea.close();
    }
    onClose(null);
  }

  const UpdateMediaInMessage = () => {
    if (markerArea) {
      markerArea.blur();
      //add overlay to media
      let newMedia = { ...media };
      //convert svg object to string
      const svgStr = new XMLSerializer().serializeToString(markerArea.markerImage);
      newMedia.markerOverLay = svgStr;
      markerArea.close();
      onClose(newMedia);
    } else {
      onClose(null);
    }
  }

  const handleShowMarkerArea = () => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.targetRoot = document.getElementsByClassName('markerjs-container')[0] as HTMLElement;
      markerArea.settings.defaultText = 'Aa';

      markerArea.show();

      //remove the marekerjs save and close button (html element title: title="Save changes")
      const markerjsUpdateButton = document.querySelector('div[title="Save changes"]');
      if (markerjsUpdateButton) {
        markerjsUpdateButton.remove();
      }

      const markerjsSaveButton = document.querySelector('div[title="Save and close"]');
      if (markerjsSaveButton) {
        markerjsSaveButton.remove();
      }

      const markerjsCloseButton = document.querySelector('div[title="Close"]');
      if (markerjsCloseButton) {
        markerjsCloseButton.remove();
      }

      markerArea.logoUI.remove();
    }
  }

  const messageModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 970,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    zIndex: 20000,
    p: 4,
  };

  return (
    <Modal onClose={CloseMessageViewImageMarker} open={true} aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={messageModalStyle}>
          <div >
            {media.markerOverLay ? (
              <h1>{t('messages.MarkerHeadingView')}</h1>
            ) : (
              <h1>{t('messages.MarkerHeadingEdit')}</h1>
            )}

            <div>
              {media.markerOverLay ? (
                <>
                  <div className="markerjs-container-view">
                    <img
                      className="markerjs-container-view-image"
                      src={media.thumpnailUrl}
                      width="900px"
                      alt="image"
                    />
                    <div className="markerjs-container-view-overlay" dangerouslySetInnerHTML={{ __html: media.markerOverLay }}></div>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '690px', maxHeight: '1000px' }}>
                  <div className="markerjs-container">
                    <img ref={imgRef}
                      src={media.thumpnailUrl}
                      width="900px"
                      alt="image"
                      onLoad={() => handleShowMarkerArea()}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              {!media.markerOverLay && (
                <div className="BottomContainer MarkerBottomContainer">
                  <Button variant="outlined" onClick={CloseMessageViewImageMarker}>{t('messages.button.cancel')}</Button>
                  <Button variant="outlined" onClick={UpdateMediaInMessage}>{t('messages.button.ok')}</Button>
                </div>
              )}
            </div>

          </div>
        </Box>
      </>
    </Modal>
  )
}

export default MessageViewImageMarker;