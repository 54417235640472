import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // path to your i18n config

import * as ReactDOM from "react-dom/client";
import { datastore } from "./datastore";
import { Customer } from "./providers/customer";
import ProtectedRoutes from "./providers/protectedRoutes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import "./index.css";
import Login from "./pages/login/login";
import Error from "./pages/error/error";
import NewOrder from "./pages/newOrder/newOrder";
import OrderList from "./pages/orderList/orderList";
import Settings from "./pages/settings/settings";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import { setContext } from "@apollo/client/link/context";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ChangePassword from "./pages/changePassword/changePassword";
import OrderInfo from "./pages/orderInfo/orderInfo";
import PublicMediaView from "./pages/publicMediaView/publicMediaView";
import AutherView from "./pages/autherView/autherView";
import MediaProcessorView from "./pages/mediaProcessorView/mediaProcessorView2";
import SubcontractorOrdersView from "./pages/subcontractorOrdersView/subcontractorOrdersView";
import MessagesView from './pages/messagesView/messagesView';
import CategorySelector from './pages/categorySelector/categorySelector';
import NewShopOrder from './pages/newShopOrder/newShopOrder';
import NewEmployeeOrder from './pages/newEmployeeOrder/newEmployeeOrder';
import OrderBookingCalender from './pages/orderBookingCalender/orderBookingCalender';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: "Bearer " + token,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

//set data from querystring
datastore.setDataFromUrl();

const loginRouter = createBrowserRouter([
  {
    path: "/planner/:token",
    element: <OrderBookingCalender />,
  },
  {
    path: "/mediaprocessor/:subcontractorOrderId/:token",
    element: <MediaProcessorView />,
  },
  {
    path: "/auther/:subcontractorOrderId/:token",
    element: <AutherView />,
  },
  {
    path: "/auther/:token",
    element: <SubcontractorOrdersView />,
  },
  {
    path: "/mediaprocessor/:token",
    element: <SubcontractorOrdersView />,
  },
  {
    path: "/media/:orderId",
    element: <PublicMediaView />,
  },
  {
    path: "newpassword/:customerId/:token",
    element: <ChangePassword />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },

  {
    path: "/messages",
    element: <MessagesView />,
  },
  {
    path: "/messages/:token",
    element: <MessagesView />,
  },
  {
    path: "/messages/:messageThreadId/:token",
    element: <MessagesView />,
  },

  
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/:t",
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/neworder",
        element: <NewOrder />,
      },
      {
        path: "/newshoporder",
        element: <NewShopOrder />,
      },
      {
        path: "/newEmployeeOrder",
        element: <NewEmployeeOrder />,
      },
      {
        path: "/categories",
        element: <CategorySelector />,
      },
      {
        path: "/orders",
        element: <OrderList />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/order/:id",
        element: <OrderInfo />,
      },
    ],
  },
  {
    path: "*",
    element: <Error errorCode={404} />,
  },
]);

root.render(
  <ApolloProvider client={client}>
    <Customer key={"customer"}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={loginRouter} ></RouterProvider>
      </I18nextProvider>
    </Customer>
  </ApolloProvider>
);
