import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./messagesView.css";
import Paper from '@mui/material/Paper';
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";
import { Card, CardContent, TextField, Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from 'react-i18next';
import MessageView from "./components/messageView";
import MessageList, { MessageFilter } from "./components/messageList";
import { useParams } from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';

const MessagesView: React.FC = () => {
  const { t } = useTranslation();
  const [updateState, setUpdateState] = React.useState(0);
  const { messageThreadId } = useParams();
  const [filter, setFilter] = useState(MessageFilter.ALL);

  let tmpSearchStr = "";
  if (messageThreadId) {
    if (localStorage.getItem("selectedCaseNo") && localStorage.getItem("selectedCaseNo") !== "null") {
      tmpSearchStr = localStorage.getItem("selectedCaseNo") as string;
    }
  }

  const [searchStr, setSearchStr] = useState(tmpSearchStr);

  let selectedTread = "";
  if (messageThreadId) {
    selectedTread = messageThreadId;
  }

  const [selectedMessageThreadId, setSelectedMessageThreadId] = useState(selectedTread as string);

  React.useEffect(() => {
    let root = document.documentElement;
    root.style.setProperty("--text-color", "#FFF");
    root.style.setProperty("--primary-color", "#000");
    root.style.setProperty("--secondary-color", "#FFF !important");
  }, []);

  const handleMessageSelected = (threadId: string, reloadList: boolean) => {
    setSelectedMessageThreadId(threadId);
    if (reloadList) {
      //reload message list
      setUpdateState(updateState + 1);
    }
  }

  const topMenuView = () => {
    if (localStorage.getItem('customerId')) {
      return viewType.EstateAgent;
    }
    return viewType.MediaProcessor;
  }

  /*
  const getSearchStr = () => {
    if (messageThreadId) {
      if (localStorage.getItem("selectedCaseNo") && localStorage.getItem("selectedCaseNo") !== "null") {
        return localStorage.getItem("selectedCaseNo") as string;
      }
    }
    return "";
  }
    */

  const handleBackButtonClicked = () => {
    window.history.back()
  }

  const handleFilterChange = (filter: any) => {
    if (filter.target.checked) {
      setFilter(MessageFilter.UNREAD);
    } else {
      setFilter(MessageFilter.ALL);
    }
  }

  let timeoutId:any = null;
  const handleSearchStrChange = (searchStr:string) => {
      //update search string when this function is not called for 500ms  
      
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
          setSearchStr(searchStr);
        }, 500);
  }

  return (
    <div className="orderInfo MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.Messages} view={topMenuView()} customerName={null} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            {messageThreadId ? (
              <div className="Heading" style={{ fontSize: '22px', height: '40px', lineHeight: '24px' }}>{t('messageViewHeadingMessages')}<br />{localStorage.getItem('selectedAddress')}</div>
            ) : (
              <div className="Heading">{t('messageViewHeadingMessages')}</div>
            )}
            {messageThreadId && (
              <div className="backButton" onClick={handleBackButtonClicked}>{t('button.back')}</div>
            )}

            <Grid container xs={12} spacing={2}>
              <Grid xs={12}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardContent style={{paddingBottom: '16px'}}>
                      <div className="FilterBar">
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                          <TextField id="search" label="Søg" variant="standard" onChange={(e) => handleSearchStrChange(e.target.value)} />  
                        </Box>

                        <div className="messageFilter">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel control={<Switch size="small" onChange={handleFilterChange} defaultChecked={filter === MessageFilter.UNREAD} />} className="switch" label={t('onlyUnreadMessages')} />
                          </FormGroup>
                        </div>
                      </div>

                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>

            <Grid container xs={12} spacing={2}>
              <Grid xs={5}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardContent>
                      <MessageList key={updateState} order={null} searchStr={searchStr} fotographerUserIds={null} onMessageSelected={handleMessageSelected} filter={filter} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid xs={7}>
                <Paper elevation={3} style={{ height: '100%' }} >
                  <Card style={{ height: '100%' }}>
                    <CardContent style={{ height: '100%' }}>
                      <MessageView threadId={selectedMessageThreadId} />
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessagesView;
